@import "common/styles/_common-include";

.radio-toolbar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: -10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: flex;
  justify-content: center;
  background-color: $white;
  padding: 12px 35px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 8px;
  margin-right: 8px;
  flex-grow: 1;
}

.radio-toolbar label:hover {
  background-color: #9ed0ed;
}

//.radio-toolbar input[type="radio"]:focus + label {
//  border: 2px dashed #444;
//}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: $mtg-light-blue;
}

.radio-toolbar .notSelected + label {
  background-color: $color-quiz-disabled;
  color: $text-color-quiz-disabled;
}
