.wrapper {
    margin-top: 124px;
    max-width: 270px;
}

@media only screen and (max-width: 950px) {
    .wrapper {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1000px) {
    .wrapper {
        padding-left: 50px;
        padding-right: 50px;
    }

}

.background {
    background: no-repeat right bottom;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 750px) {
    .background {
        background: no-repeat bottom;
        background-size: 0 0;
    }

}
