/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*::-webkit-scrollbar {*/
    /*-webkit-appearance: none;*/
    /*height: 2px;*/
    /*width: 6px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
    /*border-radius: 5px;*/
    /*background-color: rgba(0, 0, 0, .5);*/
    /*-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);*/
/*}*/

.signinImage {
    background: no-repeat right bottom;
    flex-direction: column;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.mobile-signin-label {
    display: none;
}

@media (max-width: 600px) {
    .signInimageBox {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .signInimageBox::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    @supports (backdrop-filter: none) {
        .signInimageBox::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(5px);
        }
    }

    .mobile-signin-label {
        display: flex;
        justify-content: center;
        color: rgb(114, 141, 154);
        font-weight: bold;
    }
}

.gBtn::after {
    content: "SIGN IN VIA GOOGLE";
}

.eBtn::after {
    content: "SIGN UP VIA EMAIL";
}

@media only screen and (max-width: 1000px) {
    .gBtn::after {
        content: "GOOGLE";
        padding-left: 5px;
    }

    .eBtn::after {
        content: "EMAIL";
        padding-left: 5px;

    }

    .mobile-signin-label {
        display: flex;
        justify-content: center;
        color: rgb(114, 141, 154);
        font-weight: bold;
    }

}

.dv-star-rating {
    display: flex !important;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    align-items: center;
    width: 70%;
}


#helpText>div>p{
    margin: 0;
}
