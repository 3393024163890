$font: 'Dax',
sans-serif;
$white: #fff;
$green: #007e90;
$yellow: #E0B334;
$gray: #A8B7BE;
$fontDisable: #728D9A;

.selected {
  input[type="radio"] {
    &:not(:checked) {
      + .box {
        background-color: $gray;
        p {
          color: $fontDisable;
        }
      }
    }
  }
}

.selectorBox {
  width: 100%;
  input[type="radio"] {
    display: none;
    &:checked {
      + .box {
        background-color: $yellow;
        p {
          color: white;
        }
      }
    }
  }

  .box {
    height: 152px;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 250ms ease;
    will-change: transition;
    cursor: pointer;
    position: relative;
    font-family: $font;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 0 17px 0 29px;
    &:active {
      transform: translateY(10px);
    }
    p {
      transition: all 300ms ease;
      font-size: 1.8em;
      user-select: none;
      color: $yellow;
    }
  }
}

.spacing {
  margin-top: 32px;
}

.title {
  font-weight: bold;
}

.text {
  font-weight: lighter;
  line-height: 24px;
}
