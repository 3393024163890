//Colors
$white: #ffffff;
$black: #000000;

$background: #E5E5E5;
$background-light: #E7EDF0;
$text-dark: #728D9A;
$text-black: $black;
$text-white: $white;
$text-label: #728B97;
$contrast: #A9BAC2;

$mtg-theme: #E0B334;
$mtg-blue: #52B2E2;
$mtg-light-blue: #A5D5ED;

//Color-Quiz
$color-quiz-disabled: $contrast;
$color-quiz-selected: $mtg-light-blue;
$color-quiz-background: $background-light;
$text-color-quiz-disabled: $text-dark;

//Dimensions and Measures

$page-height-without-navbar: 100vh;
$page-height-with-navbar: calc(100vh - 50px);
$page-height-with-navbar-container: calc(100vh - 50px - 60px);
$page-height-with-navbar-content: calc(100vh - 50px - 60px - 37px);

//Breakpoints

$break-phone: 320px;
$break-tablet: 768px;
$break-big: 1024px;
