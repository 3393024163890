@import "common/styles/_common-include";

.stage {
  display: flex;
  height: $page-height-with-navbar;
  background-color: $color-quiz-background;
  align-items: center;
}

.StageWrapper {
  height: 90vh;
  @media screen and (max-width: $break-tablet) {
    height: $page-height-with-navbar;
  }
}

.btn {
  width: 89%;
  @media screen and (max-width: $break-tablet) {
    width: 100%;
  }
}

.pageButton {
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: $break-tablet) {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}

.page {
  display: flex;
  height: 80vh;
  @media screen and (max-width: $break-tablet) {
    height: $page-height-with-navbar-container;
  }
}

.pageTitle {
  @media screen and (max-width: $break-tablet) {
    display: none;
  }
}

.page-content-wrapper {
  height: 94%;
  padding: 30px 30px 20px 30px;
  overflow-y: auto;
  @media screen and (max-width: $break-tablet) {
    height: $page-height-with-navbar-content;
    padding: 0px 20px 20px 20px;
    margin-top: 25px;
  }
}
