@import "common/styles/_common-include";

.scale-toolbar {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin-top: -10px;
}

.scale-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.filled {
  background-color: $mtg-light-blue !important;
}

.scale-toolbar label {
  display: flex;
  justify-content: center;
  background-color: $white;
  width: 36px;
  height: 36px;
  font-family: sans-serif, Arial;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  padding: 8px;
  margin-top: 10px;
}

.scale-toolbar label:hover {
  background-color: #9ed0ed;
}

//.scale-toolbar input[type="radio"]:focus + label {
//  border: 2px dashed #444;
//}

.scale-toolbar input[type="radio"]:checked + label {
  background-color: $mtg-light-blue;
}

.label {
  display: flex;
  color: $text-label;
  margin-top: 10px;
  padding: 0 5px 0 5px;
  justify-content: space-between;
}

.label-scale {
  @extend .label;
  flex-wrap: wrap;
}

.label-item-scale {
  display: flex;
  color: $text-label;
  justify-content: space-between;
  word-break: break-word;
  max-width: 20%;
  text-align: center;
}

.label-item-scale-vertical {
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  color: $text-label;
  justify-content: center;
  text-align: center;
}

.circleWrapper {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}

.circleWrapper-scale {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 10px;
}

.vertical-line {
  height: 15px;
  border-left: 2px solid white;
  border-right: 2px solid white;
  margin: 0 15px 0 15px;
}

.vertical-line-scale {
  justify-content: space-evenly;
  display: flex;
  height: 15px;
  border-left: 2px solid white;
  border-right: 2px solid white;
  margin: 0 15px 0 15px;
}

.scale-toolbar .notSelected + label {
  background-color: $color-quiz-disabled;
  color: $text-color-quiz-disabled;
}

.hr {
  width: 2px;
  height: 10px;
  background: white;
}
