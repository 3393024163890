.gif-box-wrapper {
    width: 100% !important;
}

.gif-list-wrapper {
    width: 100% !important;
}

.gif-list-wrapper > div {
    width: 100% !important;
}

.chat-mentions textarea {
  border: none;
  overflow-wrap: break-word;
}

.chat-mentions textarea:focus-visible {
  outline: none;
}

.mentions__mention {
  color: #E0B334;
  z-index: 1;
  font-weight: bold;
  position: relative;
  left: 1px;
  bottom: -1px;
  text-decoration: none;
  pointer-events: none;
}

.chat-mentions__suggestions {
  border-radius: 8px !important;
  padding: 5px !important;
}

.chat-mentions__highlighter {
  line-height: 16.5px;
}
