
.bar {
    border-bottom: 2px solid #C5D4DC;
    padding: 37px 35px 6px 35px;
    font-size: 12px;
    font-weight: lighter;
    color: #404040;
}

.current {
    border-bottom: 2px solid #E0B334;
    font-weight: bold;
    color: #000;
}

.complete {
    text-decoration: line-through;
}

.pageStatus {
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: -100%;
}

.steps {
    font-weight: lighter;
}

